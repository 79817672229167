// extracted by mini-css-extract-plugin
export var blog_featureSection_image = "blog-module--blog_featureSection_image--df329";
export var blog_featureSections = "blog-module--blog_featureSections--fced0";
export var blog_featureSections_block = "blog-module--blog_featureSections_block--e0c2f";
export var blog_featureSections_devTitle = "blog-module--blog_featureSections_devTitle--c00a2";
export var blog_featureSections_foodTitle = "blog-module--blog_featureSections_foodTitle--7f7f2";
export var blog_featureSections_overlay = "blog-module--blog_featureSections_overlay--a4ee2";
export var blog_featureSections_overlayLink = "blog-module--blog_featureSections_overlayLink--d8448";
export var blog_title = "blog-module--blog_title--6668d";
export var latestPosts = "blog-module--latestPosts--70cd0";
export var pageDescription = "blog-module--pageDescription--feca0";